import React from 'react';
import './PoweredByFooter.css';
import { useClientCoBranding } from '../../hooks/useClientCoBranding';
import { parseQueryString } from '../../utils/parseQueryString';
import { useFormMemory } from '../../hooks/useFormMemory';


export const PoweredByFooter: React.FC = () => {

  const [memory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const returnUrl = memory.returnUrl || unescape(queryParams.returnUrl || queryParams.ReturnUrl || 'https://www.withwine.com/');
  const [branding] = useClientCoBranding(returnUrl);


  if (!branding) {
    return (<></>);
  }

  const { displayName } = branding;

  return (
    <>
      {(displayName && !(displayName || '').toLowerCase().startsWith('withwine')) &&
        <a className='ww-powered-by' target="_blank" rel="noopener noreferrer" href="http://www.withwine.com/what-is-withwine" title="WithWine - Click for more information">
          <img src='/logo-name.png' alt='WithWine' />
        </a>}
    </>
  );
}