import React, { useCallback, FormEvent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { LinkButton } from '../LinkButton/LinkButton';
import { useFormMemory } from '../../hooks/useFormMemory';
import { PoweredByFooter } from '../PoweredByFooter/PoweredByFooter';
import { ensureUrlIsLocal } from '../../utils/urlIsLocal';
import { useConfirm2faRecoveryCodeFn } from '../../hooks/useConfirm2faCode';

export const Confirm2faRecoveryCodeForm = () => {

  const [memory] = useFormMemory();
  const [code, setCode] = useState<string>('');
  const [codeErrors, setCodeErrors] = useState<string[]>([]);
  const [showHelpText, setShowHelpText] = useState(false);
  const [confirmRecoveryCode, confirmRecoveryCodeIsReady, { loading, success, errors }] = useConfirm2faRecoveryCodeFn();

  useEffect(() => {
    if (errors && errors.length > 0) {
      setCodeErrors(errors.map(e => e.error));
    }
  }, [errors, code, setCodeErrors]);

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    if (!confirmRecoveryCodeIsReady) {
      return;
    }

    let codeErrors: string[] = [];
    
    if (!code) {
      codeErrors = ['Code is required.'];
    }

    if (codeErrors.length > 0) {
      setCodeErrors(codeErrors);
      return;
    } else {
      setCodeErrors([]);
    }

    confirmRecoveryCode(code, { returnUrl: memory.returnUrl });

  }, [confirmRecoveryCode, confirmRecoveryCodeIsReady, code, memory ]);

  const toggleHelpText = (show: boolean) => {
    setShowHelpText(show);
  };

  if (success) {
    window.location.href = ensureUrlIsLocal(memory.returnUrl);
    return <div className="spinner"></div>
  }

  return (
    <>
      <h1 className='typo-header'>Recovery Code</h1>
      <p className='typo-content'>
        Your account is protected with 2 factor authentication. Enter one of your recovery codes below.
      </p>
      <form onSubmit={onSubmit}>
        <Input
          type='text'
          inputMode='numeric'
          name='code'
          placeholder='Recovery Code'
          style={{ 'textAlign': 'center' }}
          onChange={(e) => setCode((e.target.value + '').trim())}
          autoComplete={'nope'}
          errors={codeErrors}
          disabled={loading}
        />

        <div className="ww-button-row">
          <PoweredByFooter />
          <Button primary disabled={loading || !confirmRecoveryCodeIsReady} type="submit">Continue</Button>
        </div>
        <div className='ww-link-footer'>
          <LinkButton>
            <Link to={`/Account/Login/2fa${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`}><i className='fa fa-angle-left'></i>Back</Link>
          </LinkButton>
          |
          <LinkButton type='button' onClick={() => toggleHelpText(true)}><i className='fa fa-info-circle'></i>Having trouble?</LinkButton>
        </div>
      </form>
      <div className={"ww-help-panel fade " + (showHelpText ? "in" : "out")}>
          <p>
            If your recovery codes aren't working:
          </p>
          <ul>
          <li>Contact WithWine support <a href="mailto:hello@withwine.com">hello@withwine.com</a></li>
        </ul>
        <p>
          <LinkButton type='button' onClick={() => toggleHelpText(false)}><i className='fa fa-times-circle'></i>Hide</LinkButton>
        </p>
        </div>
    </>
  );
}
