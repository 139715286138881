import React from 'react';
import { PasswordRequirementProps } from './PasswordRequirementProps';
import './PasswordRequirement.css';

export const PasswordRequirement: React.FC<PasswordRequirementProps> = ({ isComplete, children }) => {
  return (
    <span className={`ww-password-requirement ${isComplete === true ? 'complete' : (isComplete === false ? 'incomplete' : '')}`}>
      {children && <span>{children}</span>}
      {isComplete === true && <i className='fa fa-check'><span className='sr-only'>(complete)</span></i>}
      {isComplete === false && <i className='fa fa-times'><span className='sr-only'>(incomplete)</span></i>}
    </span>
  );
}