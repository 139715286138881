import { createContext, useContext, Dispatch, SetStateAction } from 'react';

export interface FormMemoryInterface {
  email: string;
  returnUrl: string;
  confirmationCode: string;
  termsAndConditionText: string;
  newPassword: string;
  loginInfo: string;
}

export const defaultFormMemory = {
  email: '',
  returnUrl: '',
  confirmationCode: '',
  termsAndConditionText: '',
  newPassword: '',
  loginInfo: ''
};

export const FormMemory = createContext<[FormMemoryInterface, Dispatch<SetStateAction<FormMemoryInterface>>]>([defaultFormMemory, () => defaultFormMemory]);

export const useFormMemory = () => {
  return useContext(FormMemory);
}

