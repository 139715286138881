import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { parseQueryString } from '../../utils/parseQueryString';
import { useGetMagicCode } from '../../hooks/useGetMagicCode';
import { isValidEmail } from '../../utils/isValidEmail';
import { useFormMemory } from '../../hooks/useFormMemory';

export const RegistrationStart: React.FC = () => {
  const [memory, setMemory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const returnUrl = memory.returnUrl || unescape(queryParams.ReturnUrl || 'https://www.withwine.com/');
  const [email] = useState(queryParams.email || '');
  const [getMagicCode, { success: magicCodeSuccess, loading: magicCodeLoading }] = useGetMagicCode();

  useEffect(() => {
    if (email && isValidEmail(email)) {
      if (!magicCodeLoading && magicCodeSuccess == null) {
        getMagicCode(email, returnUrl)
      }
    }
  }, [getMagicCode, magicCodeLoading, email, returnUrl, magicCodeSuccess]);

  if (!email || !isValidEmail(email) || magicCodeSuccess === false) {
    return <Redirect to={`/Account/Login${window.location.search}`} />;
  }

  if (magicCodeSuccess) {
    setMemory({ 
      ...memory,
      email
    });
    return <Redirect to={`/Account/Login/Code${window.location.search}`} />;
  }

  return (
    <>
      <div className="spinner"></div>
    </>
  )
};