import React from 'react';
import { Link } from 'react-router-dom';
import { LinkButton } from '../components/LinkButton/LinkButton';

export const Home: React.FC = () => {
  return (
    <div>
      <div className='ww-login'>
        <div className='ww-login-content'>
          <div className='ww-client-logo'>
            <img src='https://secure.withwine.com/Images/email-logo.png' alt='WithWine' />
          </div>
          <LinkButton>
            <div>
              <Link to='/Account/Login'>Continue to Login <i className='fa fa-angle-right'></i></Link>
            </div>
          </LinkButton>
        </div>
      </div>
    </div>
  );
}