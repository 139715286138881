import 'whatwg-fetch';
import { useState, useCallback } from 'react';
import { useXSRFTokenContext } from './useXSRFTokenContext';
import { DataResponse, ActionResponseData } from '../models/DataResponse';
import { Error } from '../models/Error';

const endPoint = process.env.REACT_APP_API_ENDPOINT;

type ConfirmCode = [
  (
    email: string, 
    code: string,
    returnUrl: string,
    firstName?: string,
    lastName?: string,
    newPassword?: string,
    acceptTerms?: boolean
  ) => void, 
  { 
    loading: boolean, 
    errors: {field: string, error: string}[] | null, 
    success: boolean | null
  }
];

type ConfirmEmailResponse = {
  emailAddress: string,
  confirmationCode: string,
  firstName: string | null,
  lastName: string | null
};

export const useConfirmEmail = (onResponse?: (response: ConfirmEmailResponse) => void, on2faRequired?: (is2faRequired: boolean) => void): ConfirmCode => {
  const xsrfToken = useXSRFTokenContext();

  const [result, setResult] = useState<{ loading: boolean, errors: Error[] | null, success: boolean | null }>({
    loading: false, errors: null, success: null
  });

  const confirmEmail = useCallback((
    email: string, 
    code: string,
    returnUrl: string,
    firstName?: string,
    lastName?: string,
    newPassword?: string,
    acceptTerms?: boolean
  ) => {
    setResult(result => { return { ...result, success: null, errors: null }; });
    if (xsrfToken) {
      const data = new FormData();
      data.append('emailAddress', email);
      data.append('confirmationCode', code);
      data.append('returnUrl', returnUrl);

      if (firstName) {
        data.append('firstName', firstName);
      }

      if (lastName) {
        data.append('lastName', lastName);
      }

      if (newPassword) {
        data.append('newPassword', newPassword);
      }

      if (acceptTerms) {
        data.append('acceptTermsAndConditions', 'true');
      }

      setResult(result => { return { ...result, loading: true }; });

      fetch(`${endPoint}/Account/ConfirmEmail`, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': xsrfToken,
          'Accept': 'application/json'
        },
        body: data
      }).then(async (response) => {
        const responseData = (await response.json()) as DataResponse<ConfirmEmailResponse | ActionResponseData>;
        setResult({ loading: false, errors: responseData.errors, success: responseData.type === 'Redirect' });

        if (responseData.type === 'Action' && (responseData.data as ActionResponseData).action === 'SendCode') {
          if (on2faRequired) {
            on2faRequired(true);
          }
        } else if (onResponse && responseData.data) {
          onResponse(responseData.data as ConfirmEmailResponse);
        }
      }).catch((errors) => {
        setResult({ loading: false, errors: errors, success: false });
      });
    }
  }, [xsrfToken, onResponse, setResult, on2faRequired]);

  return [confirmEmail, { ...result }];
}