import { ResponseStatus, useApiRequestFn } from '../useApiRequest';
import { useCallback } from 'react';

type RecoveryCodesViewModel = { recoveryCodes: string[] | null, statusMessage: string | null };

export const useGenerateRecoveryCodesFn = (): [() => void, boolean, ResponseStatus, RecoveryCodesViewModel | null] => {
  const [generateRecoveryCodesFunction, isReady, status, recoveryCodes] = useApiRequestFn<RecoveryCodesViewModel>({
    basePathAndQuery: '/Authenticator/GenerateRecoveryCodes',
    method: 'POST',
    useXsrfToken: true
  });

  const generateRecoveryCodesFunctionWrapper = useCallback(() => {
    if (!isReady) {
      return;
    }
    const data = new FormData();

    generateRecoveryCodesFunction({
      appendPathAndQuery: '',
      body: data
    })
  }, [isReady, generateRecoveryCodesFunction]);

  return [generateRecoveryCodesFunctionWrapper, isReady, status, recoveryCodes];
}