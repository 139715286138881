export const parseQueryString = (string: string) => {
  const pairs = string.split('?')[1] || string.split('?')[0];
  return parseKeyValuePairs(pairs, undefined, undefined, k => k, v => decodeURIComponent(v || ''));
}

export const parseCookieString = (string: string) => {
  return parseKeyValuePairs(string, ';', '=', k => (k || '').trim(), v => decodeURIComponent((v || '').trim()));
}

const parseKeyValuePairs = (string: string, delimiter: string = '&', pairDelimiter: string = '=',
  keyFormat = (x: string) => x, valueFormat = (x: string) => x,
  allowMultipleValues: boolean = false): { [key: string]: string } => {
  var split = string.split(delimiter)
    .map((queryParam) => {
      let kvp = queryParam.split(pairDelimiter)
      return { key: keyFormat(kvp[0]), value: valueFormat(kvp[1]) };
    });

  if (allowMultipleValues) {
    return split.reduce((query: any, kvp) => {
      query[kvp.key] = query[kvp.key] || [];
      query[kvp.key].push(kvp.value);
      return query;
    }, {});
  }

  return split.reduce((query: any, kvp) => {
    query[kvp.key] = kvp.value;
    return query;
  }, {});  
}