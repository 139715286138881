import { ResponseStatus, useApiRequestFn } from '../useApiRequest';
import { useCallback } from 'react';
import { ActionResponseData } from '../../models/DataResponse';

export const useAuthenticatorDisableFn = (): [() => void, boolean, ResponseStatus, ActionResponseData | null] => {
  const [authenticatorDisableFunction, isReady, status, action] = useApiRequestFn<ActionResponseData | null>({
    basePathAndQuery: '/Authenticator/Disable',
    method: 'POST',
    useXsrfToken: true
  });

  const authenticatorDisableFunctionWrapper = useCallback(() => {
    if (!isReady) {
      return;
    }
    const data = new FormData();

    authenticatorDisableFunction({
      appendPathAndQuery: '',
      body: data
    })
  }, [isReady, authenticatorDisableFunction]);

  return [authenticatorDisableFunctionWrapper, isReady, status, action];
}