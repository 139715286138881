import { ResponseStatus, useApiRequestFn } from './useApiRequest';
import { useCallback } from 'react';

export const useConfirm2faCodeFn = (): [(code: string, options: { rememberMe: boolean, trustBrowserFor2fa: boolean, returnUrl: string, provider?: string }) => void, boolean, ResponseStatus] => {
  const [enableFunction, isReady, status] = useApiRequestFn<any>({
    basePathAndQuery: '/Account/VerifyCode',
    method: 'POST',
    useXsrfToken: true
  });

  const verifyCodeFunctionWrapper = useCallback((code: string, options: { rememberMe?: boolean, trustBrowserFor2fa: boolean, returnUrl: string, provider?: string }) => {
    if (!isReady) {
      return;
    }
    const data = new FormData();

    data.append('code', code);
    data.append('returnUrl', options.returnUrl);
    data.append('rememberBrowser', options.trustBrowserFor2fa ? 'true' : 'false');

    if (typeof options.provider !== 'undefined') {
      data.append('provider', options.provider);
    }
    if (typeof options.rememberMe !== 'undefined') {
      data.append('rememberMe', options.rememberMe ? 'true' : 'false');
    }

    enableFunction({
      appendPathAndQuery: '',
      body: data
    })
  }, [isReady, enableFunction]);

  return [verifyCodeFunctionWrapper, isReady, status];
}

export const useConfirm2faRecoveryCodeFn = (): [(code: string, options: { returnUrl: string }) => void, boolean, ResponseStatus] => {
  const [enableFunction, isReady, status] = useApiRequestFn<any>({
    basePathAndQuery: '/Account/VerifyRecoveryCode',
    method: 'POST',
    useXsrfToken: true
  });

  const verifyCodeFunctionWrapper = useCallback((code: string, options: { returnUrl: string }) => {
    if (!isReady) {
      return;
    }
    const data = new FormData();

    data.append('code', code);
    data.append('returnUrl', options.returnUrl);

    enableFunction({
      appendPathAndQuery: '',
      body: data
    })
  }, [isReady, enableFunction]);

  return [verifyCodeFunctionWrapper, isReady, status];
}