import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { parseQueryString } from '../../utils/parseQueryString';
import { useClientCoBranding } from '../../hooks/useClientCoBranding';
import { Branding } from '../../components/Branding/Branding';
import { Authenticator } from '../../components/Manage/Authenticator/Authenticator'
import { AuthenticatorEnable } from '../../components/Manage/Authenticator/AuthenticatorEnable'
import { useFormMemory } from '../../hooks/useFormMemory';
import './Manage.css';

export const Manage: React.FC = () => {
  const [memory, setMemory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const returnUrl = memory.returnUrl || unescape(queryParams.returnUrl || queryParams.ReturnUrl || 'https://www.withwine.com/');
  const [branding] = useClientCoBranding(returnUrl);

  useEffect(() => {
    setMemory((memory) => {
      return {
        ...memory,
        returnUrl
      }
    });
  }, [returnUrl, setMemory]);

  return (
    <div className='ww-manage-container'>
      <div className='ww-hero-image-container'>
        <div className='ww-hero-image' style={(branding && branding.heroImageUri && { backgroundImage: "url('" + branding.heroImageUri + "')", backgroundPosition: branding.heroImageCssBackgroundPosition || 'center' }) || {}}>
        </div>
      </div>
      <div className='ww-login'>
        <div className='ww-login-content'>
          <Branding data={branding} />
          <Route path='/Account/Manage/Authenticator' exact={true} component={Authenticator} />
          <Route path='/Account/Manage/Authenticator/Enable' exact={true} component={AuthenticatorEnable} />
        </div>
      </div>
    </div>
  );
};
