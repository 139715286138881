import React from 'react';
import { useXSRFToken } from '../../hooks/useXSRFToken';
import { XSRFTokenContext } from '../../hooks/useXSRFTokenContext';

export const RequireXSRFToken: React.FC = ({ children }) => {

  const [token, { loading, errors }] = useXSRFToken();

  if (loading) {
    return null;
  }

  if (!loading && !token) {
    return <>{errors && JSON.stringify(errors)}</>
  }

  return (
    <XSRFTokenContext.Provider value={token}>{children}</XSRFTokenContext.Provider>
  );
}