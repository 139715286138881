import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { parseQueryString } from '../../utils/parseQueryString';
import { useClientCoBranding } from '../../hooks/useClientCoBranding';
import { Branding } from '../../components/Branding/Branding';
import { CredentialsForm } from '../../components/CredentialsForm/CredentialsForm';
import { EmailForm } from '../../components/EmailForm/EmailForm';
import { ConfirmCodeForm } from '../../components/ConfirmCodeForm/ConfirmCodeForm';
import { Confirm2faForm } from '../../components/Confirm2faForm/Confirm2faForm';
import { Confirm2faRecoveryCodeForm } from '../../components/Confirm2faForm/Confirm2faRecoveryCodeForm';
import { useFormMemory } from '../../hooks/useFormMemory';
import { RegistrationForm } from '../../components/RegistrationForm/RegistrationForm';
import { RegistrationStart } from '../../components/RegistrationStart/RegistrationStart';
import './Login.css';

export const Login: React.FC = () => {
  const [memory, setMemory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const returnUrl = memory.returnUrl || unescape(queryParams.returnUrl || queryParams.ReturnUrl || 'https://www.withwine.com/');
  const [branding] = useClientCoBranding(returnUrl);
  const loginInfo = memory.loginInfo || (branding && branding.loginInfo) || unescape(queryParams.login_info || queryParams.loginInfo || queryParams.LoginInfo || '');

  useEffect(() => {
    setMemory((memory) => {
      return {
        ...memory,
        returnUrl,
        loginInfo
      }
    });
  }, [returnUrl, loginInfo, setMemory]);

  return (
    <div className='ww-login-container'>
      <div className='ww-hero-image-container'>
        <div className='ww-hero-image' style={(branding && branding.heroImageUri && { backgroundImage: "url('" + branding.heroImageUri + "')", backgroundPosition: branding.heroImageCssBackgroundPosition || 'center' }) || {}}>
        </div>
      </div>
      <div className='ww-login'>
        <div className='ww-login-content'>
          <Branding data={branding} />
          <Route path='/Account/Login' exact={true} component={EmailForm} />
          <Route path='/Account/Login/Password' exact={true} component={CredentialsForm} />
          <Route path='/Account/Login/Register/Init' exact={true} component={RegistrationStart} />
          <Route path='/Account/Login/Code' exact={true} component={ConfirmCodeForm} />
          <Route path='/Account/Login/Register' exact={true} component={RegistrationForm} />
          <Route path='/Account/Login/2fa' exact={true} component={Confirm2faForm} />
          <Route path='/Account/Login/2faRecoveryCode' exact={true} component={Confirm2faRecoveryCodeForm} />
        </div>
      </div>
    </div>
  );
};
