import React, { useState, useCallback, FormEvent } from 'react';
import { Redirect } from 'react-router';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { parseQueryString } from '../../utils/parseQueryString';
import { useLogin } from '../../hooks/useLogin';
import { useGetMagicCode } from '../../hooks/useGetMagicCode';
import { isValidEmail } from '../../utils/isValidEmail';
import { LinkButton } from '../LinkButton/LinkButton'; 
import { useFormMemory } from '../../hooks/useFormMemory';
import { PoweredByFooter } from '../PoweredByFooter/PoweredByFooter';
import { ensureUrlIsLocal } from '../../utils/urlIsLocal';

export const CredentialsForm: React.FC = () => {
  const [memory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const returnUrl = memory.returnUrl || unescape(queryParams.ReturnUrl || 'https://www.withwine.com/');
  const [password, setPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [goBack, setGoBack] = useState(false);
  const [login, { success, errors, requires2fa, loading: loginLoading }] = useLogin();
  const [getMagicCode, { success: magicCodeSent, loading: magicCodeLoading, errors: magicCodeErrors }] = useGetMagicCode();
  const disableForm = magicCodeSent === true || magicCodeLoading || loginLoading;
  let passwordErrorMessage = '';
  let magicCodeErrorMessage = '';

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    if (disableForm) {
      return;
    }
         
    let emailErrors: string[] = [];
    let passwordErrors: string[] = [];

    if (!memory.email || !isValidEmail(memory.email)) {
      return <Redirect to={`/Account/Login${window.location.search}`} />;
    }

    if (!password) {
      passwordErrors = ['Password is required.'];
    }

    if (emailErrors.length > 0 || passwordErrors.length > 0) {
      setPasswordErrors(passwordErrors);
      return;
    }

    setPasswordErrors([]);
    
    login(memory.email, password, returnUrl);
    
  }, [memory.email, password, returnUrl, login, disableForm]);

  const onMagicCodeSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    if (!disableForm) {
      getMagicCode(memory.email, returnUrl)
    }
  }, [memory.email, returnUrl, disableForm, getMagicCode]);

  if (goBack || !memory.email || !isValidEmail(memory.email)) {
    return <Redirect to={`/Account/Login${window.location.search}`} />;
  }

  if (requires2fa) {
    return <Redirect to={`/Account/Login/2fa${window.location.search}`} />;
  }

  if (success) {
    window.location.href = ensureUrlIsLocal(returnUrl);
    return <div className="spinner"></div>;
  }

  if (magicCodeSent) {
    return <Redirect to={`/Account/Login/Code${window.location.search}`} />;
  }

  if (errors && errors.length > 0) {
    const generalError = (errors.find((error) => error.field === ''));
    if (generalError) {
      passwordErrorMessage = generalError.error;
    }
  }

  if (magicCodeErrors && magicCodeErrors.length > 0) {
    const errorMessages = (magicCodeErrors.filter((item) => item.error !== '')).map(item => item.error).join(', ');
    if (errorMessages) {
      magicCodeErrorMessage = errorMessages;
    }
  }
  
  return (
    <>
      <h1 className='typo-header'>Have an account?</h1>
      <p className='typo-content'>
        Login using your WithWine account. <a className="typo-soft" href="http://www.withwine.com/what-is-withwine" target="_blank" rel="noopener noreferrer"><small>What's this?</small></a><br />
        <small>
          Enter the password associated with <strong>{memory.email}</strong>, then click &quot;Login&quot;.
        </small>
      </p>
      <form onSubmit={onSubmit}>
        <Input 
          type='password' 
          placeholder='Password' 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          errors={passwordErrors}
          error={!!passwordErrorMessage}
        />
        {passwordErrorMessage && <p className='typo-error'>{passwordErrorMessage}</p>}
        <div className="ww-button-row">
          <PoweredByFooter />
          <Button type='button' disabled={disableForm} onClick={onMagicCodeSubmit}>Create / Forgot Password</Button>
          <Button type='submit' disabled={disableForm} primary>Login</Button>
        </div>
        <hr />
        <h1 className='typo-header'>Continue without password?</h1>
        {magicCodeErrorMessage && <p className='typo-error'>{magicCodeErrorMessage}</p>}
        <div className="ww-button-row">
          <Button type='button' disabled={disableForm} primary onClick={onMagicCodeSubmit}>Continue</Button>
        </div>
        <div className='ww-link-footer'>
          <LinkButton onClick={() => setGoBack(true)}><i className='fa fa-angle-left'></i>Back</LinkButton>
        </div>
      </form>
    </>
  )
};