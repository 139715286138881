import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '../../Button/Button';
import { useAuthenticatorSettings } from '../../../hooks/Authenticator/useAuthenticatorSettings'
import { useGenerateRecoveryCodesFn } from '../../../hooks/Authenticator/useGenerateRecoveryCodes'
import { useForgetBrowserFn } from '../../../hooks/Authenticator/useForgetBrowser'
import { useAuthenticatorDisableFn } from '../../../hooks/Authenticator/useAuthenticatorDisable'
import { useAuthenticatorResetFn } from '../../../hooks/Authenticator/useAuthenticatorReset'

export const Authenticator: React.FC = () => {
  const history = useHistory();

  const [statusMessage, setStatusMessage] = useState('');
  const [authenticatorSettingsStatus, authenticatorSettings] = useAuthenticatorSettings();
  const [generateRecoveryCodes, generateRecoveryCodesIsReady, generateRecoveryCodesStatus, recoveryCodeResult] = useGenerateRecoveryCodesFn();
  const [forgetBrowser, forgetBrowserIsReady, forgetBrowserStatus] = useForgetBrowserFn();
  const [disableAuthenticator, disableAuthenticatorIsReady, disableAuthenticatorStatus] = useAuthenticatorDisableFn();
  const [resetAuthenticator, resetAuthenticatorIsReady, resetAuthenticatorStatus] = useAuthenticatorResetFn();

  useEffect(() => setStatusMessage(authenticatorSettings ? (authenticatorSettings.statusMessage || '') : ''), [authenticatorSettings, setStatusMessage]);
  useEffect(() => setStatusMessage(recoveryCodeResult ? (recoveryCodeResult.statusMessage || '') : ''), [recoveryCodeResult, setStatusMessage]);
  useEffect(() => {
    if (disableAuthenticatorStatus.success) {
      history.go(0);
    }
  }, [disableAuthenticatorStatus, history]);
  useEffect(() => {
    if (resetAuthenticatorStatus.success) {
      history.push('/Account/Manage/Authenticator/Enable');
    }
  }, [resetAuthenticatorStatus, history]);

  const forgetBrowserSuccess = forgetBrowserStatus.success;
  useEffect(() => {
    if (forgetBrowserSuccess) {
      setStatusMessage('The current browser is no longer trusted. When you login again from this browser you will be asked for your two factor authentication code.');
    }
  }, [forgetBrowserSuccess, setStatusMessage]);

  const onEnableAuthenticator = () => {
    history.push('/Account/Manage/Authenticator/Enable');
  };
  
  return (
    <>
      <h1 className='typo-header'>Two Factor Authentication</h1>
      {statusMessage && <div className='typo-alert'><p>{statusMessage}</p></div>}
      {authenticatorSettingsStatus.loading && <div className="spinner"></div>}
      {!authenticatorSettingsStatus.loading && authenticatorSettings == null && <p className='typo-content'>Please <Link to='/Account/Login?ReturnUrl=/Account/Manage/Authenticator'>login</Link> to continue.</p>}
      {authenticatorSettingsStatus.errors && authenticatorSettingsStatus.errors.length && authenticatorSettingsStatus.errors.length > 0 &&
        <>
          {authenticatorSettingsStatus.errors.map((e, i) => <p key={i} className='typo-error'>{e.error}</p>)}
        </>}
      {authenticatorSettingsStatus.success && authenticatorSettings &&
        <>
        {authenticatorSettings.is2faEnabled ?
          <>
            <h2 className='typo-sub-header'>Recovery Codes</h2>
            {recoveryCodeResult && recoveryCodeResult.recoveryCodes && recoveryCodeResult.recoveryCodes.length > 0 ?
              <>
                <div className='typo-alert'>
                  <p>
                    <strong>Put these codes in a safe place.</strong>
                  </p>
                  <p>
                    If you lose your device and don't have the recovery codes you will lose access to your account.
                  </p>
                  <p>
                    Generating new recovery codes does not change the keys used in authenticator apps. If you wish to change the key used in an authenticator app you should reset your authenticator keys below.
                  </p>
                </div>
                <div className='panel mb'>
                  {recoveryCodeResult.recoveryCodes.map(code => <p key={code}><kbd>{code}</kbd></p>)}
                </div>
              </>:
              <>
                {authenticatorSettings.recoveryCodesLeft <= 5 && <p className='typo-warning'>You have {authenticatorSettings.recoveryCodesLeft} recovery {authenticatorSettings.recoveryCodesLeft === 1 ? 'code' : 'codes'} left.</p>}
                {authenticatorSettings.recoveryCodesLeft > 0 && <p className='typo-content'>If you generate new recovery codes, your existing codes will no longer work.</p>}
              </>}
            <Button type='button' onClick={() => generateRecoveryCodes()} disabled={!generateRecoveryCodesIsReady || generateRecoveryCodesStatus.loading}>Generate New Recovery Codes</Button>

            {forgetBrowserStatus.success ?
              <>
              </> :
              <>
                {authenticatorSettings.isMachineRemembered && <>
                  <h2 className='typo-sub-header'>Trusted Browser</h2>
                  <p className='typo-content'>This browser has been remembered as trusted so will not require two factor authentication on every login attempt. You should "forget this browser" if it is accessible by others.</p>
                  <Button type='button' onClick={() => forgetBrowser()} disabled={!forgetBrowserIsReady || forgetBrowserStatus.loading}>Forget This Browser</Button>
                </>}
              </>}
            <h2 className='typo-sub-header'>Disable</h2>
            <Button type='button' onClick={() => disableAuthenticator()} disabled={!disableAuthenticatorIsReady || disableAuthenticatorStatus.loading || (disableAuthenticatorStatus.success || false)}>Disable Two Factor Authentication</Button>
          </> :
          <></>}
        <h2 className='typo-sub-header mb-0'>Authenticator App</h2>
        <p className='typo-content mb-0'>You can use an Authenticator App for two factor authentication such as:</p>
        <ul className='typo-content'>
          <li>
            Microsoft Authenticator for <a href="https://go.microsoft.com/fwlink/?Linkid=825072">Android</a> and <a href="https://go.microsoft.com/fwlink/?Linkid=825073">iOS</a>
          </li>
          <li>
            Google Authenticator for <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en">Android</a> and <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a>
          </li>
        </ul>
        {authenticatorSettingsStatus.success && authenticatorSettings.hasAuthenticator ? 
          <>
            <Button type='button' onClick={onEnableAuthenticator} className='mb'>Setup Authenticator App</Button>
            <Button type='button' onClick={() => resetAuthenticator()} disabled={!resetAuthenticatorIsReady || resetAuthenticatorStatus.loading || (resetAuthenticatorStatus.success || false)}>Reset Authenticator App</Button>
          </> :
          <></>
        }
        {authenticatorSettingsStatus.success && !authenticatorSettings.hasAuthenticator ?
          <Button type='button' onClick={onEnableAuthenticator}>Add Authenticator App</Button>
          : <></>}
        </>}
    </>
  )
};