import 'whatwg-fetch';
import { useEffect, useState } from 'react';
import { DataResponse } from '../models/DataResponse';
import { Error } from '../models/Error';

const endPoint = process.env.REACT_APP_API_ENDPOINT;

export interface BrandingData { 
  displayName: string;
  displayLogoUri: string | null;
  heroImageUri: string | null;
  heroImageCssBackgroundPosition: string | null;
  brandId: string | null;
  loginInfo: string | null;
}

type ClientCoBranding = [BrandingData | null, { loading: boolean, errors: {field: string, error: string}[] | null }];

export const useClientCoBranding = (returnUrl: string): ClientCoBranding => {

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[] | null>(null);
  const [brandingData, setBrandingData] = useState<BrandingData | null>(null);
  
  useEffect(() => {
    if (returnUrl) {
      setLoading(true);
      const data = new FormData();
      data.append('returnUrl', returnUrl);
      fetch(`${endPoint}/Account/ClientCoBranding`, {
        method: 'POST',
        body: data
      }).then(async (response) => {
        const responseData = (await response.json()) as DataResponse<BrandingData>;
        setLoading(false);
        setErrors(responseData.errors);
        setBrandingData(responseData.data);
      }).catch((errors) => {
        setLoading(false);
        setErrors(errors);
      })
    }
  }, [returnUrl]);

  return [brandingData, { loading, errors }];
}