import 'whatwg-fetch';
import { useState, useCallback } from 'react';
import { useXSRFTokenContext } from './useXSRFTokenContext';
import { DataResponse, ActionResponseData } from '../models/DataResponse';
import { Error } from '../models/Error';

const endPoint = process.env.REACT_APP_API_ENDPOINT;

type Login = [(email: string, password: string, returnUrl: string) => void, { loading: boolean, errors: {field: string, error: string}[] | null, success: boolean | null, requires2fa: boolean | null }];

export const useLogin = (): Login => {

  const xsrfToken = useXSRFTokenContext();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[] | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [requires2fa, setRequires2fa] = useState<boolean | null>(null);

  const login = useCallback((email: string, password: string, returnUrl: string) => {
    if (xsrfToken) {
      const data = new FormData();
      data.append('email', email);
      data.append('password', password);
      data.append('rememberMe', 'true');
      data.append('returnUrl', returnUrl);

      setLoading(true);
      fetch(`${endPoint}/Account/Login`, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': xsrfToken,
          'Accept': 'application/json'
        },
        body: data
      }).then(async (response) => {
        const responseData = (await response.json()) as DataResponse;
        setLoading(false);
        setErrors(responseData.errors);

        if (responseData.type === 'Action' && (responseData.data as ActionResponseData).action === 'SendCode') {
          setRequires2fa(true);
        } else if (!responseData.errors) {
          setRequires2fa(false);
        }

        setSuccess(!responseData.errors);
      }).catch((errors) => {
        setLoading(false);
        setErrors(errors);
      });
    }
  }, [xsrfToken, setLoading, setErrors, setSuccess]);

  return [login, { loading, errors, success, requires2fa }];
}