import { ResponseStatus, useApiRequestOnLoad, useApiRequestFn } from '../useApiRequest';
import { useCallback } from 'react';

type EnableAuthenticatorViewModel = {
  sharedKey: string | null,
  authenticatorUri: string | null,
  statusMessage: string | null
};

export const useAuthenticatorEnable = (): [ResponseStatus, EnableAuthenticatorViewModel | null] => {
  return useApiRequestOnLoad<EnableAuthenticatorViewModel>({
    pathAndQuery: '/Authenticator/Enable',
    method: 'GET'
  });
};

export const useAuthenticatorEnablePostFn = (): [(code: string) => void, boolean, ResponseStatus] => {
  const [enableFunction, isReady, status] = useApiRequestFn<any>({
    basePathAndQuery: '/Authenticator/Enable',
    method: 'POST',
    useXsrfToken: true
  });

  const enableFunctionWrapper = useCallback((code: string) => {
    if (!isReady) {
      return;
    }
    const data = new FormData();
    data.append('code', code);

    enableFunction({
      appendPathAndQuery: '',
      body: data
    })
  }, [isReady, enableFunction]);

  return [enableFunctionWrapper, isReady, status];
}