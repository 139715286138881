import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Input } from '../../Input/Input';
import { Button } from '../../Button/Button';
import { useAuthenticatorEnable, useAuthenticatorEnablePostFn } from '../../../hooks/Authenticator/useAuthenticatorEnable'

const endPoint = process.env.REACT_APP_API_ENDPOINT;

export const AuthenticatorEnable: React.FC = () => {

  const [code, setCode] = useState('');
  const [initAuthenticatorEnableStatus, initAuthenticatorEnable] = useAuthenticatorEnable();
  const [enableAuthenticator, isEnableReady, enableAuthenticatorStatus] = useAuthenticatorEnablePostFn();
  const codeErrors = enableAuthenticatorStatus.errors ? enableAuthenticatorStatus.errors.map(e => e.error) : [];

  const onEnableAuthenticator = useCallback(() => {
    if (!isEnableReady) {
      return;
    }
    enableAuthenticator(code);
  }, [code, enableAuthenticator, isEnableReady]);

  if (enableAuthenticatorStatus.success) {
    return <Redirect to={'/Account/Manage/Authenticator'} />;
  }
  
  return (
    <>
      <h1 className='typo-header'>Enable Two Factor Authentication</h1>
      {initAuthenticatorEnable && initAuthenticatorEnable.statusMessage && <div className='typo-alert'><p>{initAuthenticatorEnable.statusMessage}</p></div>}
      {(initAuthenticatorEnableStatus.loading || !isEnableReady) ?
        <div className="spinner"></div>
        :
        (!initAuthenticatorEnable ?
          <>
            <p className='typo-content'>Please <Link to='/Account/Login?ReturnUrl=/Account/Manage/Authenticator'>login</Link> to continue.</p>
          </>
          :
          <>
            <p className='typo-content mb-0'>To use an authenticator app, follow the steps below:</p>
            <ol className='typo-content'>
              <li>
                Download an authenticator app like
              Microsoft Authenticator (<a href="https://go.microsoft.com/fwlink/?Linkid=825072">Android</a> and <a href="https://go.microsoft.com/fwlink/?Linkid=825073">iOS</a>) or
              Google Authenticator (<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en">Android</a> and <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a>).
            </li>
              <li>
                <p className='typo-content'>Scan the QR Code or enter the following key into your authenticator app. Spaces and casing do not matter.</p>
                <p style={{ textAlign: 'center' }}><kbd>{initAuthenticatorEnable.sharedKey}</kbd></p>
                <p style={{ textAlign: 'center' }}>
                  <img src={`${endPoint}/Authenticator/QrCode?payload=${encodeURIComponent(initAuthenticatorEnable.authenticatorUri || '')}`} alt={'QR Code URL: ' + initAuthenticatorEnable.authenticatorUri} style={{ maxWidth: '100%' }} />
                </p>
              </li>
              <li>
                <p className='typo-content'>
                  Once you have scanned the QR code or input the key above, your authenticator app will provide you
                  with a unique code. Enter the code in the confirmation box below.
              </p>
                <Input autoComplete='authcode' name='code' placeholder='Enter Verification Code Here'
                  style={{ 'textAlign': 'center' }}
                  onChange={(e) => setCode((e.target.value + '').trim())}
                  errors={codeErrors}
                  disabled={enableAuthenticatorStatus.loading} />
                <Button type='button' disabled={enableAuthenticatorStatus.loading} onClick={onEnableAuthenticator} primary>Verify</Button>
              </li>
            </ol>

          </>)}
    </>
  )
};