import React from 'react';
import { BrandingData } from '../../hooks/useClientCoBranding';

interface BrandingProps {
  data: BrandingData | null;
}

export const Branding: React.FC<BrandingProps> = ({ data }) => {

  if (!data || !data.displayName || data.displayName.startsWith('WithWine')) {
    return (
      <>
        <div className='ww-client-logo'>
          <img src='https://secure.withwine.com/Images/email-logo.png' alt='WithWine' />
        </div>
      </>
    );
  }

  const { displayLogoUri, displayName } = data;

  return (
    <>
      <div className='ww-client-logo'>
        {displayLogoUri && <img src={displayLogoUri} alt={displayName || 'Withwine'} />}
        {!displayLogoUri && displayName !== 'withwine' && <h1>{displayName}</h1>}
        {!displayLogoUri && displayName === 'withwine' && <img src='https://secure.withwine.com/Images/email-logo.png' alt='WithWine' />}
      </div>
    </>
  );
}