import React from 'react';
import { ButtonProps } from './ButtonProps';
import './Button.css';
import { NavLink } from 'react-router-dom';

export const Button: React.FC<ButtonProps> = ({ children, primary, to, ...buttonProps }) => {

  if (to) {
    return (
      <div className={`ww-button${primary ? ' primary inverted': ''}${buttonProps.disabled ? ' disabled' : ''}`}>
        <NavLink to={to} className='typo-cta'>{children}</NavLink>
      </div>
    );
  }

  return (
    <div className={`ww-button${primary ? ' primary inverted': ''}${buttonProps.disabled ? ' disabled' : ''}`}>
      <button {...buttonProps}><span className='typo-cta'>{children}</span></button>
    </div>
  )
}