import React, { useCallback, FormEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { LinkButton } from '../LinkButton/LinkButton';
import { PasswordRequirement } from '../PasswordRequirement/PasswordRequirement';
import { useConfirmEmail } from '../../hooks/useConfirmEmail';
import { useFormMemory } from '../../hooks/useFormMemory';
import { useGetMagicCode } from '../../hooks/useGetMagicCode';
import { PoweredByFooter } from '../PoweredByFooter/PoweredByFooter';
import { ensureUrlIsLocal } from '../../utils/urlIsLocal';

export const ConfirmCodeForm = () => {

  const history = useHistory();
  const [memory, setMemory] = useFormMemory();
  const [confirmEmail, { loading, success, errors }] = useConfirmEmail(
    response => {
      setMemory({ ...memory, confirmationCode: response.confirmationCode });
    },
    is2faRequired => {
      history.push(`/Account/Login/2fa${window.location.search}`);
    });
  const [code, setCode] = useState<string>('');
  const [codeErrors, setCodeErrors] = useState<string[]>([]);
  const [password, setPassword] = useState<string>('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [getMagicCode, { success: magicCodeSent, loading: magicCodeLoading }] = useGetMagicCode();
  const [resendDisableCounter, setResendDisableCounter] = useState(0);
  const [showHelpText, setShowHelpText] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      const newUser = errors.find(error => error.field === 'AcceptTermsAndConditions');
      const codeError = errors.find(error => error.field === 'ConfirmationCode');
      const newPassword = errors.find(error => error.field === 'NewPassword');

      if (codeError && codeError.error) {
        setCodeErrors([codeError.error])
      }

      if (newPassword && newPassword.error) {
        setPasswordErrors([newPassword.error])
      }

      if (newUser) {
        setMemory((memory) => {
          return {
            ...memory,
            termsAndConditionText: newUser.error,
            newPassword: password
          }
        });
      }
    }
  }, [errors, code, password, setCodeErrors, setMemory]);

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    let codeErrors: string[] = [];
    
    if (!code) {
      codeErrors = ['Code is required.'];
    } else if (code.length !== 6) {
      codeErrors = ['Code should be exactly six characters in length.'];
    }

    if (codeErrors.length > 0) {
      setCodeErrors(codeErrors);
      return;
    } else {
      setCodeErrors([]);
    }

    confirmEmail(memory.email, code, memory.returnUrl, undefined, undefined, password);

  }, [code, memory, password, confirmEmail]);

  const toggleHelpText = (show: boolean) => {
    setShowHelpText(show);
  };

  const onMagicCodeSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    setResendDisableCounter((resendDisableCounter) => {
      if (magicCodeLoading || resendDisableCounter !== 0) {
        return resendDisableCounter;
      }
      getMagicCode(memory.email, memory.returnUrl);
      setShowHelpText(true);
      return 30;
    });
  }, [memory, magicCodeLoading, getMagicCode, setResendDisableCounter]);

  useEffect(() => {
    const id = setInterval(() => {
      setResendDisableCounter((resendDisableCounter) => {
        if (resendDisableCounter > 0) { 
          return resendDisableCounter-1;
        }
        return resendDisableCounter;
      });
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [setResendDisableCounter]);

  if (memory.confirmationCode && memory.termsAndConditionText) {
    return <Redirect to={`../Login/Register${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`} />
  }

  if (!memory.email) {
    return <Redirect to={`../Login${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`} />
  }

  if (success) {
    window.location.href = ensureUrlIsLocal(memory.returnUrl);
    return <div className="spinner"></div>
  }

  return (
    <>
      <h1 className='typo-header'>Verify your email</h1>
      <p className='typo-content'>
        We've sent an email with a 6-digit code to <strong>{memory.email}</strong>.<br />
        It will expire in a few minutes, so enter it soon.
      </p>
      <form onSubmit={onSubmit}>
        <Input
          type='text'
          inputMode='numeric'
          name='code'
          placeholder='XXXXXX'
          style={{ 'textAlign': 'center' }}
          onChange={(e) => setCode((e.target.value + '').trim())}
          autoComplete={'nope'}
          errors={codeErrors}
          disabled={loading}
        />
        <Input 
          type='password' 
          placeholder='Set a new password (optional)' 
          onChange={(e) => setPassword(e.target.value)}
          autoComplete={'new-password'}
          errors={passwordErrors}
          disabled={loading}
        />
        <p className='typo-content'>
          <small>
            <>Passwords must be </>
            <PasswordRequirement isComplete={password ? (password.length >= 8 ? true : false) : null}>at least 8 characters long</PasswordRequirement>
            <> with at least one </>
            <PasswordRequirement isComplete={password ? (/[0-9]/.test(password) ? true : false) : null}>number</PasswordRequirement><>, </>
            <PasswordRequirement isComplete={password ? (/[^a-zA-Z0-9]/.test(password) ? true : false) : null}>symbol</PasswordRequirement><>, </>
            <PasswordRequirement isComplete={password ? (/[A-Z]/.test(password) ? true : false) : null}>uppercase</PasswordRequirement>
            <> and </>
            <PasswordRequirement isComplete={password ? (/[a-z]/.test(password) ? true : false) : null}>lowercase</PasswordRequirement>
            <> letter</>
          </small>
        </p>

        <div className="ww-button-row">
          <PoweredByFooter />
          <Button onClick={onMagicCodeSubmit} disabled={resendDisableCounter !== 0} type="button">
            {resendDisableCounter === 0 && <>Resend Code</>}
            {resendDisableCounter !== 0 && (magicCodeSent ? `Sent` : 'Sending New Code...')}
          </Button>
          <Button primary disabled={loading} type="submit">Continue</Button>
        </div>
        <div className='ww-link-footer'>
          <LinkButton>
            <Link to={`../Login/Password${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`}><i className='fa fa-angle-left'></i>Back</Link>
          </LinkButton>
          |
          <LinkButton type='button' onClick={() => toggleHelpText(true)}><i className='fa fa-info-circle'></i>Having trouble?</LinkButton>
        </div>
      </form>
      <div className={"ww-help-panel fade " + (showHelpText ? "in" : "out")}>
          <p>
            If you are unable to locate your 6-digit code, try the following:
          </p>
          <ul>
            <li>Check your SPAM folder</li>
            <li>Search your email for <strong>contact@withwine.com</strong></li>
            <li>Double-check your email address is spelled correctly</li>
            <li>Check the email domain ends correctly, for example <strong>gmail.com</strong> and not <strong>gmail.com.au</strong></li>
        </ul>
        <p>
          <LinkButton type='button' onClick={() => toggleHelpText(false)}><i className='fa fa-times-circle'></i>Hide</LinkButton>
        </p>
        </div>
    </>
  );
}
