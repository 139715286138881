import React from 'react';
import { CheckboxProps } from './CheckboxProps';
import './Checkbox.css';

export const Checkbox: React.FC<CheckboxProps> = ({ checked, children, onClick, error, className }) => {
  return (
    <div className={`ww-checkbox${checked ? ' checked' : ''}${error ? ' error' : ''}${className ? (' ' + className) : ''}`} onClick={onClick} role="checkbox" aria-checked={checked} tabIndex={0}>
      <div className='ww-checkbox-tick'>
        <i className='fa fa-check'></i>
      </div>
      {children && <div className='typo-content'>{children}</div>}
    </div>
  );
}