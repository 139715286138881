import React, { useState, useCallback, FormEvent } from 'react';
import { Redirect } from 'react-router';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { parseQueryString } from '../../utils/parseQueryString';
import { isValidEmail } from '../../utils/isValidEmail';
import { useFormMemory } from '../../hooks/useFormMemory';
import { LoginInfoEnum } from '../../constants/LoginInfoEnum'
import { useClientCoBranding } from '../../hooks/useClientCoBranding';
import { LinkButton } from '../LinkButton/LinkButton';
import { PoweredByFooter } from '../PoweredByFooter/PoweredByFooter';

export const EmailForm: React.FC = () => {
  const [memory, setMemory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [email, setEmail] = useState(queryParams.email || '');
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const returnUrl = memory.returnUrl || unescape(queryParams.returnUrl || queryParams.ReturnUrl || 'https://www.withwine.com/');
  const [branding] = useClientCoBranding(returnUrl);
  let generalErrorMessage = '';

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    setHasSubmitted(true);
    
    let emailErrors: string[] = [];
    let passwordErrors: string[] = [];

    if (!email) {
      emailErrors = ['Email is required.'];
    } else if (!isValidEmail(email)) {
      emailErrors = ['Invalid email.'];
    }

    if (emailErrors.length > 0 || passwordErrors.length > 0) {
      setEmailErrors(emailErrors);
      return;
    }

    setEmailErrors([]);
    setEmailSuccess(true);
    
  }, [email, setHasSubmitted]);

  if (emailSuccess) {
    setMemory({ 
      ...memory,
      email
    });
    return <Redirect to={`/Account/Login/Password${window.location.search}`} />;
  }
  
  return (
    <>
      <h1 className='typo-header'>
        {(() => {
          switch ((memory.loginInfo || '').toLowerCase()) {
            case LoginInfoEnum.Checkout:
              return 'Continue to Checkout';
            case LoginInfoEnum.Membership:
              return 'Continue to Membership';
            case LoginInfoEnum.Redeem:
              return 'Continue to Gift Card Redemption';
            case LoginInfoEnum.Login:
              return 'Secure Login';
            case LoginInfoEnum.Register:
              return 'Register an Account';
            default:
              return 'Continue to ' + ((branding && branding.displayName) || 'WithWine');
          }
        })()}
      </h1>
      <p className='typo-content'>
        Enter your email address.
      </p>
      <form onSubmit={onSubmit}>
        <Input
          className='wide-field'
          autoFocus
          type='email'
          placeholder='Email'
          value={email} onChange={(e) => {
            if (hasSubmitted) {
              const email = e.target.value;
              let emailErrors: string[] = [];

              if (!email) {
                emailErrors = ['Email is required.'];
              } else if (!isValidEmail(email)) {
                emailErrors = ['Invalid email.'];
              }

              setEmailErrors(emailErrors);
            }
            setEmail(e.target.value);
          }}
          errors={emailErrors}
          error={!!generalErrorMessage}
        />
        <div className="ww-button-row">
          <PoweredByFooter />
          {memory.loginInfo === LoginInfoEnum.Login && <LinkButton onClick={() => setMemory({ ...memory, loginInfo: LoginInfoEnum.Register })}>Register</LinkButton>}
          {memory.loginInfo === LoginInfoEnum.Register && <LinkButton onClick={() => setMemory({ ...memory, loginInfo: LoginInfoEnum.Login })}>Login</LinkButton>}
          <Button type='submit' primary>Next</Button>
        </div>
      </form>
    </>
  )
};