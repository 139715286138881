import 'whatwg-fetch';
import { useState, useCallback } from 'react';
import { useXSRFTokenContext } from './useXSRFTokenContext';
import { DataResponse } from '../models/DataResponse';
import { Error } from '../models/Error';

const endPoint = process.env.REACT_APP_API_ENDPOINT;

type GetMagicCode = [(email: string, returnUrl: string) => void, { loading: boolean, errors: {field: string, error: string}[] | null, success: boolean | null }];

export const useGetMagicCode = (): GetMagicCode => {

  const xsrfToken = useXSRFTokenContext();

  const [result, setResult] = useState<{ loading: boolean, errors: Error[] | null, success: boolean | null }>({
    loading: false, errors: null, success: null
  });

  const getMagicCode = useCallback((email: string, returnUrl: string) => {
    setResult(result => { return { ...result, success: null, errors: null }; })
    if (xsrfToken) {
      const data = new FormData();
      data.append('email', email);
      data.append('returnUrl', returnUrl);
      setResult(result => { return { ...result, loading: true }; });

      fetch(`${endPoint}/Account/MagicCode`, {
        method: 'POST',
        headers: {
          'X-XSRF-Token': xsrfToken,
          'Accept': 'application/json'
        },
        body: data
      }).then(async (response) => {
        const responseData = (await response.json()) as DataResponse;
        setResult({ loading: false, errors: responseData.errors, success: !responseData.errors });
      }).catch((errors) => {
        setResult({ loading: false, errors: errors, success: false });
      });
    }
  }, [xsrfToken, setResult]);

  return [getMagicCode, { ...result }];
}