import React, { useCallback, FormEvent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { LinkButton } from '../LinkButton/LinkButton';
import { useFormMemory } from '../../hooks/useFormMemory';
import { PoweredByFooter } from '../PoweredByFooter/PoweredByFooter';
import { ensureUrlIsLocal } from '../../utils/urlIsLocal';
import { useConfirm2faCodeFn } from '../../hooks/useConfirm2faCode';

export const Confirm2faForm = () => {

  const [memory] = useFormMemory();
  const [code, setCode] = useState<string>('');
  const [codeErrors, setCodeErrors] = useState<string[]>([]);
  const [showHelpText, setShowHelpText] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [trustBrowser, setTrustBrowser] = useState(false);
  const [confirm2faCode, confirm2faCodeIsReady, { loading, success, errors }] = useConfirm2faCodeFn();

  useEffect(() => {
    if (errors && errors.length > 0) {
      setCodeErrors(errors.map(e => e.error));
    }
  }, [errors, code, setCodeErrors]);

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    if (!confirm2faCodeIsReady) {
      return;
    }

    let codeErrors: string[] = [];
    
    if (!code) {
      codeErrors = ['Code is required.'];
    } else if (code.length !== 6) {
      codeErrors = ['Code should be exactly six characters in length.'];
    }

    if (codeErrors.length > 0) {
      setCodeErrors(codeErrors);
      return;
    } else {
      setCodeErrors([]);
    }

    confirm2faCode(code, { returnUrl: memory.returnUrl, rememberMe: rememberMe, trustBrowserFor2fa: trustBrowser });

  }, [confirm2faCode, confirm2faCodeIsReady, code, memory, rememberMe, trustBrowser ]);

  const toggleHelpText = (show: boolean) => {
    setShowHelpText(show);
  };

  if (success) {
    window.location.href = ensureUrlIsLocal(memory.returnUrl);
    return <div className="spinner"></div>
  }

  return (
    <>
      <h1 className='typo-header'>Verification Code</h1>
      <p className='typo-content'>
        Your account is protected with 2 factor authentication. Enter your authentication code below.
      </p>
      <form onSubmit={onSubmit}>
        <Input
          type='text'
          inputMode='numeric'
          name='code'
          placeholder='XXXXXX'
          style={{ 'textAlign': 'center' }}
          onChange={(e) => setCode((e.target.value + '').trim())}
          autoComplete={'nope'}
          errors={codeErrors}
          disabled={loading}
        />
        <Checkbox onClick={() => setRememberMe(!rememberMe)} checked={rememberMe} className='mb'>Remember me</Checkbox>
        <Checkbox onClick={() => setTrustBrowser(!trustBrowser)} checked={trustBrowser}>Trust this device</Checkbox>

        <div className="ww-button-row">
          <PoweredByFooter />
          <Button primary disabled={loading || !confirm2faCodeIsReady} type="submit">Continue</Button>
        </div>
        <div className='ww-link-footer'>
          <LinkButton>
            <Link to={`/Account/Login/Password${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`}><i className='fa fa-angle-left'></i>Back</Link>
          </LinkButton>
          |
          <LinkButton type='button' onClick={() => toggleHelpText(true)}><i className='fa fa-info-circle'></i>Having trouble?</LinkButton>
        </div>
      </form>
      <div className={"ww-help-panel fade " + (showHelpText ? "in" : "out")}>
          <p>
            If you are unable to locate your 6-digit code or authenticator device, try the following:
          </p>
          <ul>
          <li>
            If you saved your recovery codes, you can login using <Link to={`/Account/Login/2faRecoveryCode${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`}>a recovery code</Link>
          </li>
          <li>Contact WithWine support <a href="mailto:hello@withwine.com">hello@withwine.com</a></li>
        </ul>
        <p>
          <LinkButton type='button' onClick={() => toggleHelpText(false)}><i className='fa fa-times-circle'></i>Hide</LinkButton>
        </p>
        </div>
    </>
  );
}
