import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { Login } from './pages/Login/Login';
import { Manage } from './pages/Manage/Manage';
import { NotFound } from './pages/NotFound';
import { RequireXSRFToken } from './components/RequireXSRFToken/RequireXSRFToken';
import './App.css';
import { FormMemoryProvider } from './components/FormMemory/FormMemory';

const App: React.FC = () => {
  return (
    <div className="App">
      <RequireXSRFToken>
        <Router>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/Account/Login' component={() => <FormMemoryProvider><Login /></FormMemoryProvider>} />
            <Route path='/Account/Manage' component={() => <FormMemoryProvider><Manage /></FormMemoryProvider>} />
            <Route path='' component={NotFound} />
          </Switch>
        </Router>
      </RequireXSRFToken>
    </div>
  );
}

export default App;
