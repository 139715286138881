import { ResponseStatus, useApiRequestFn } from '../useApiRequest';
import { useCallback } from 'react';
import { ActionResponseData } from '../../models/DataResponse';

export const useForgetBrowserFn = (): [() => void, boolean, ResponseStatus, ActionResponseData | null] => {
  const [forgetBrowserFunction, isReady, status, action] = useApiRequestFn<ActionResponseData | null>({
    basePathAndQuery: '/Authenticator/ForgetBrowser',
    method: 'POST',
    useXsrfToken: true
  });

  const forgetBrowserFunctionWrapper = useCallback(() => {
    if (!isReady) {
      return;
    }
    const data = new FormData();

    forgetBrowserFunction({
      appendPathAndQuery: '',
      body: data
    })
  }, [isReady, forgetBrowserFunction]);

  return [forgetBrowserFunctionWrapper, isReady, status, action];
}