import React from 'react';
import { Link } from 'react-router-dom';
import { LinkButton } from '../components/LinkButton/LinkButton';

export const NotFound: React.FC = () => {
  return (
    <div>
      <div className='ww-login'>
        <div className='ww-login-content'>
          <div className='ww-client-logo'>
            <img src='https://secure.withwine.com/Images/email-logo.png' alt='WithWine' />
          </div>
          <p className='typo-content'>
            Sorry, the requested page could not be found.
          </p>
          <LinkButton>
            <Link to='/Account/Login'><i className='fa fa-angle-left'></i>Return to Login</Link>
          </LinkButton>
        </div>
      </div>
    </div>
  );
}