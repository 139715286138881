import React, { useRef, useEffect, useState } from 'react';
import { InputProps } from './InputProps';
import './Input.css';

export const Input: React.FC<InputProps> = ({ children, prepend, append, errors, error, ...inputProps }) => {

  const ref = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const hasErrors = (errors && errors.length > 0) || error;

  useEffect(() => {
    const elem = ref.current;
    if (elem) {
      const onFocus = () => {
        setFocus(true);
      };
      const onBlur = () => {
        setFocus(false);
      }
      elem.addEventListener('focus', onFocus);
      elem.addEventListener('blur', onBlur);
      return () => {
        elem.removeEventListener('focus', onFocus);
        elem.removeEventListener('blur', onBlur);
      }
    }
  }, [setFocus])

  return (
    <>
      <div className={`ww-input${focus ? ' focused' : ''}${hasErrors ? ' error' : ''}`}>
        {prepend}
        <input {...inputProps} ref={ref} />
        {append}
      </div>
      {errors && errors.length > 0 && <p className='typo-error'>
        {errors!.join(' ')}
      </p>}
    </>
  );
}