import 'whatwg-fetch';
import { useState, useEffect, useCallback } from 'react';
import { DataResponse } from '../models/DataResponse';
import { Error } from '../models/Error';
import { useXSRFTokenContext } from './useXSRFTokenContext';

const endPoint = process.env.REACT_APP_API_ENDPOINT;

export type ResponseStatus = { loading: boolean, errors: Error[] | null, success: boolean | null };


export const useApiRequestFn = <T>({ basePathAndQuery, method, useXsrfToken }:
  { basePathAndQuery: string, method: 'GET' | 'POST' | string, useXsrfToken?: boolean }): [(options: { appendPathAndQuery: string, body?: FormData }) => void, boolean, ResponseStatus, T | null] => {

  const requiresXsrfToken = typeof(useXsrfToken) === 'undefined' ? (method.toUpperCase() === 'POST') : useXsrfToken;
  const xsrfToken = useXSRFTokenContext();
  const [isReady, setIsReady] = useState<boolean>(!requiresXsrfToken);
  const [resultStatus, setResultStatus] = useState<ResponseStatus>({
    loading: false, errors: null, success: null
  });
  const [responseData, setResponseData] = useState<T | null>(null);

  useEffect(() => {
    setIsReady(!requiresXsrfToken || (xsrfToken && xsrfToken.length && xsrfToken.length > 0 ? true : false));
  }, [xsrfToken, requiresXsrfToken, setIsReady]);

  const apiRequest = useCallback(({ appendPathAndQuery, body }: { appendPathAndQuery: string, body?: FormData }) => {
    if (!isReady) {
      return;
    }

    setResultStatus(result => { return { ...result, loading: true }; });

    const xsrfTokenHeader : Record<string, string> = requiresXsrfToken ? { 'X-XSRF-Token': xsrfToken || '' } : { };

    fetch(`${endPoint}${basePathAndQuery}${appendPathAndQuery}`, {
      method: method,
      headers: {
        ...xsrfTokenHeader,
        'Accept': 'application/json'
      },
      body: body
    }).then(async (response) => {
      const responseData = (await response.json()) as DataResponse<T>;
      ;
      setResultStatus({ loading: false, errors: responseData.errors, success: !responseData.errors });
      setResponseData(responseData.data);
    }).catch((errors) => {
      setResultStatus({ loading: false, errors: errors, success: false });
    });
  }, [setResultStatus, xsrfToken, requiresXsrfToken, isReady, method, basePathAndQuery]);

  return [apiRequest, isReady, { ...resultStatus }, responseData];
}

export const useApiRequestOnLoad = <T>({ pathAndQuery, method, body, useXsrfToken }:
  { pathAndQuery: string, method: 'GET' | 'POST' | string, body?: FormData, useXsrfToken?: boolean }): [ResponseStatus, T | null] => {

  const [apiFunction, isReady, status, data] = useApiRequestFn<T>(
    { basePathAndQuery: pathAndQuery, method: method, useXsrfToken: useXsrfToken });

  useEffect(() => {
    if (isReady) {
      apiFunction({
        appendPathAndQuery: '',
        body: body
      });
    }
  }, [isReady, apiFunction, body]);

  return [status, data];
}