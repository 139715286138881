import React, { useState, FormEvent, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Input } from '../Input/Input';
import { Checkbox } from '../Checkbox/Checkbox';
import { Button } from '../Button/Button';
import { parseQueryString } from '../../utils/parseQueryString';
import { useFormMemory } from '../../hooks/useFormMemory';
import { useConfirmEmail } from '../../hooks/useConfirmEmail';
import { PoweredByFooter } from '../PoweredByFooter/PoweredByFooter';
import { ensureUrlIsLocal } from '../../utils/urlIsLocal';

export const RegistrationForm: React.FC = () => {

  const history = useHistory();
  const [memory, setMemory] = useFormMemory();
  const queryParams = parseQueryString(window.location.search || '');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToTermsError, setAgreeToTermsError] = useState(false);
  const [firstName, setFirstName] = useState(unescape(queryParams.firstName || ''));
  const [firstNameErrors, setFirstNameErrors] = useState<string[]>([]);
  const [lastName, setLastName] = useState(unescape(queryParams.lastName || ''));
  const [lastNameErrors, setLastNameErrors] = useState<string[]>([]);
  const [confirmEmail, { success, errors }] = useConfirmEmail(
    (response) => {
      if (!firstName && !lastName) {
        if (response.firstName) {
          setFirstName(response.firstName);
        }
        if (response.lastName) {
          setLastName(response.lastName);
        }
      }
    },
    is2faRequired => {
      history.push(`/Account/Login/2fa${window.location.search}`);
    });

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    let firstNameErrors: string[] = [];
    let lastNameErrors: string[] = [];

    if (!firstName) {
      firstNameErrors = ['Please provide your first name'];
    }

    if (!lastName) {
      lastNameErrors = ['Please provide your last name'];
    }

    if (firstNameErrors.length > 0 || lastNameErrors.length > 0 || !agreeToTerms) {
      setFirstNameErrors(firstNameErrors);
      setLastNameErrors(lastNameErrors);
      setAgreeToTermsError(!agreeToTerms);
      return;
    } else {
      setFirstNameErrors([]);
      setLastNameErrors([]);
    }

    confirmEmail(memory.email, memory.confirmationCode, memory.returnUrl, firstName, lastName, memory.newPassword, true);

  }, [confirmEmail, memory, firstName, lastName, agreeToTerms, setAgreeToTermsError, setFirstNameErrors, setLastNameErrors]);

  if (success) {
    window.location.href = ensureUrlIsLocal(memory.returnUrl);
    return <div className="spinner"></div>;
  }

  if (success === false && errors && errors.find && errors.find(error => error.field === 'ConfirmationCode') && memory.email) {
    setMemory({ ...memory, confirmationCode: '', termsAndConditionText: '' });
    //Temporary code expired. Resend a new one.
    return <Redirect to={`/Account/Login/Register/Init?ReturnUrl=${escape(memory.returnUrl || '')}&email=${escape(memory.email)}`} />
  }

  if (!memory.email || !memory.confirmationCode || !memory.termsAndConditionText) {
    if (memory.email) {
      return <Redirect to={`../Login/Code${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`} />
    }
    return <Redirect to={`../Login${memory.returnUrl ? `?ReturnUrl=${escape(memory.returnUrl)}` : ''}`} />
  }

  return (
    <>
      <h1 className='typo-header'>What's your name?</h1>
      <form onSubmit={onSubmit}>
        <Input 
          type='text' 
          className='wide-field'
          placeholder='First name' 
          onChange={(e) => setFirstName((e.target.value + '').trim())} 
          errors={firstNameErrors}
        />
        <Input 
          type='text' 
          className='wide-field'
          placeholder='Last name' 
          onChange={(e) => setLastName((e.target.value + '').trim())} 
          errors={lastNameErrors}
        />
        <div className='ww-login-checkboxes'>
          <Checkbox onClick={() => setAgreeToTerms(!agreeToTerms)} checked={agreeToTerms} error={agreeToTermsError}>
            I agree to the <a href='https://secure.withwine.com/TermsOfUse' 
              rel="noopener noreferrer"
              target='_blank' className='typo-link'>Terms of Use</a>
          </Checkbox>
        </div>
        <div className="ww-button-row">
          <PoweredByFooter />
          <Button primary>Continue</Button>
        </div>
        <div className='ww-link-footer'>
          <button className='ww-link-button' onClick={() => { setMemory({ ...memory, confirmationCode: '', termsAndConditionText: '' }); }}>
            <i className='fa fa-angle-left'></i>Back
          </button>
        </div>
      </form>
    </>
  );
}

      