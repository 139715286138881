import 'whatwg-fetch';
import { useState, useEffect } from 'react';
import { parseCookieString } from '../utils/parseQueryString';
import { Error } from '../models/Error';

const endPoint = process.env.REACT_APP_API_ENDPOINT;

type XSRFTokenType = [string | null, {loading: boolean, errors: {field: string, error: string}[] | null}];

export const useXSRFToken = (): XSRFTokenType => {

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error[] | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    fetch(`${endPoint}/Home/XsrfToken`, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(() => {
      const cookies = parseCookieString(document.cookie);
      const token = cookies['XSRF-TOKEN'] || null;
      setToken(token);
      setLoading(false);
      if (!token) {
        setErrors([{ field: '', error: 'An error has occurred.' }]);
      } else {
        setErrors(null);
      }
    }).catch((errors) => {
      setLoading(false);
      setErrors(errors);
    });
  }, []);

  return [token, { loading, errors }];
}